import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  &:last-child {
    border-bottom: none;
  }
`;

export const StyledButton = styled.button`
  border-radius: 10px;
  border: none;
  background-color: var(--secondary);
  padding: 15px 35px;
  font-weight: bold;
  color: var(--secondary-text);
  cursor: pointer;
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: white;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  background-color: var(--accent);
  border-radius: 2.5%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: white;
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click below to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [networkId, setNetworkId] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    "NETWORK": {
      "ETH": {
        "CONTRACT_ADDRESS": "0xC9ca4Ec0Ff1fF3CeC884344a36D36121B9164005",
        "SYMBOL": "ETH",
        "ID": 1,
        "WEI_COST": 1500000000000000,
        "DISPLAY_COST": 0.0015,
        "MARKETPLACE": "OpenSea",
        "MARKETPLACE_LINK": "https://opensea.io/collection/hexadecimal-nft"
      },
      "POLY": {
        "SYMBOL": "Matic",
        "ID": 137,
        "WEI_COST": 1500000000000000000,
        "DISPLAY_COST": 1.5,
        "CONTRACT_ADDRESS": "0xbFb2e7b482f92c4224c8433c97169f6e3301a28A",
        "MARKETPLACE": "OpenSea",
        "MARKETPLACE_LINK": ""
      }
    }
  });
  const [selectedNetwork, setSelectedNetwork] = useState(CONFIG.NETWORK.ETH);

  useEffect(() => {
    switch (networkId) {
      case '1':
        setSelectedNetwork(CONFIG.NETWORK.ETH);
        break;
      case '137':
        setSelectedNetwork(CONFIG.NETWORK.POLY);
        break;
      default:
        console.log('Unsupported network');
        break;
    }
  }, [networkId]);


  const claimNFTs = async () => {
    let cost = selectedNetwork.WEI_COST;
    let totalCostWei = String(cost * mintAmount);
    setFeedback(`Mint your Hex NFT...`);
    setClaimingNft(true);

    try {
      // Estimate the gas required for the transaction
      let gasLimitEstimate = await blockchain.smartContract.methods
        .safeMint(mintAmount)
        .estimateGas({ from: blockchain.account, value: totalCostWei });

      // Add a 3% buffer to the estimated gas limit
      let totalGasLimit = Math.floor(gasLimitEstimate * 1.021);
      if (selectedNetwork.SYMBOL == 'Matic') {
        console.log('heee')
        totalGasLimit = Math.floor(gasLimitEstimate * 5);
      }

      console.log("Cost: ", totalCostWei);
      console.log("Gas limit: ", totalGasLimit);

      // Define a base options object
      let options = {
        gasLimit: String(totalGasLimit),
        to: selectedNetwork.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei
      };

      // If gasPrice is available, add it to the options
      if (selectedNetwork.SYMBOL == 'Matic') {

        options = { ...options, gasPrice: 120000000000 }; // 150 gwei
      }


      await blockchain.smartContract.methods
        .safeMint(mintAmount)
        .send(options)
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong please try again later." + err);
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `Congratulations, you've succesfully minted your NFT!`
          );
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        });
    } catch (error) {
      console.log(error);
      setFeedback("Sorry, something went wrong please try again later." + error);
      setClaimingNft(false);
    }
  };

  const handleChangeNetwork = (event) => {
    const newNetworkId = event.target.value;
    setNetworkId(newNetworkId);
  };



  const handleConnect = () => {
    // Logic to connect to the selected network goes here...
    dispatch(connect(networkId));
    getData();
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 25) {
      newMintAmount = 25;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };


  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
    console.log('config', CONFIG.NETWORK)
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
      >
        <ResponsiveWrapper flex={1} test>
     

        <s.Container
  flex={2}
  jc={"center"}
  style={{ backgroundColor: "var(--accent)", padding: '105px' }}
>
  <StyledLogo alt={"logo"} src={"/logo-horizontal.png"} style={{ marginTop: -30, marginBottom: -5 }} />

  <s.TextTitle
    style={{ textAlign: "center", fontSize: 15, fontWeight: "bold", marginBottom: 30, marginTop: 5, color: "var(--accent-text)" }}
  >
    Join Us in Illuminating Lives through the Power of Color
  </s.TextTitle>

  <s.TextDescription
    style={{ fontSize: 18, }}
  >
              Hex NFT brings together <span style={{ fontWeight: 'bold' }}>16,781,312 unique</span> colors and the potential of NFTs to change lives. <br />By claiming your unique color, you are contributing to a brighter future for those affected by all forms of vision deficiencies, from color blindness to glaucoma.
  </s.TextDescription>

  <s.SpacerMedium />

  <s.TextTitle style={{ fontSize: 30, fontWeight: 'bold' }}>
    Visual Disorders Statistics
  </s.TextTitle>

  <div className="table">
    <div className="row">
      <div className="cell" style={{ textAlign: "right" }}>Percentage of Population Affected</div>
    </div>
    {[
      ['Regular Vision (Trichromatic)', 'Can distinguish all three primary color, little to no blurriness', '68%'],
      ['Protanomaly', 'Reduced sensitivity to red - trouble distinguishing reds and greens', '1.3%'],
      ['Protanopia', 'Red blind - Can’t see reds at all', '1.5%'],
      ['Deuteranomaly', 'Reduced sensitivity to green - Trouble distinguishing reds and greens', '5.3%'],
      ['Deuteranopia', 'Green blind - Can’t see greens at all', '1.2%'],
      ['Tritanomaly', 'Trouble distinguishing blues and greens, and yellows and reds', '0.02%'],
      ['Tritanopia', 'Unable to distinguish between blues and greens, purples and reds, and yellows and pinks', '0.03%'],
      ['Achromatomaly', 'Partial color blindness, sees the absence of most colors', '0.09%'],
      ['Achromatopsia', 'Complete color blindness, can only see shades', '0.05%'],
      ['Cataracts', 'Clouding of the lens in the eye that affects vision', '33%'],
      ['Glaucoma', 'Slight vision loss', '2%'],
      ['Low Vision', 'Decreased and/or blurry vision (not fixable by usual means such as glasses)', '33%'],
    ].map(([disorder, description, percentage]) =>
      <div className="row" key={disorder}>
        <div className="cell"><s.TextDescription style={{fontWeight: 'bold', padding: 0, margin:0 }}>{disorder} </s.TextDescription><small>{description}</small></div>
        <div className="cell" style={{ textAlign: "right" }}>{percentage}</div>
      </div>
    )}
  </div>

  <s.SpacerLarge />

  <s.TextDescription style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 20 }}>
    What’s in the Palette for You?
  </s.TextDescription>

  <s.TextDescription>
    As part of the Hex NFT family, you get unique ownership of a distinct color and the freedom to name your color. <br />You also get the chance to secure rare 3-digit color codes, access to future airdrops, and become an active participant in a project that evolves and expands, offering more benefits over time. The best part? You contribute to improving the lives of people dealing with vision impairments, one color at a time.
  </s.TextDescription>

  <s.SpacerLarge />

  <s.TextTitle style={{ fontSize: 30, fontWeight: "bold", marginTop: 30, }}>
    🌈 Roadmap 🌈
  </s.TextTitle>
  <StyledLink target={"_blank"} style={{ color: 'black', fontWeight: 'bold', marginBottom: 30 }} href="/HexNFT-Whitepaper.pdf">
      Click to read whitepaper
  </StyledLink>


  <s.TextDescription style={{ fontSize: 22, fontWeight: 'bold', marginBottom: 10 }}>
    1️⃣ Hex: A Dawn of Colorful Possibilities
  </s.TextDescription>

  <s.TextDescription style={{ fontSize: 18, marginBottom: 20 }}>
    With Hex, we're embarking on a vibrant journey. We're giving you the ability to mint, name, and own your unique color NFTs. But this is just the beginning.
  </s.TextDescription>

  <s.TextDescription style={{ fontSize: 22, fontWeight: 'bold', marginBottom: 10 }}>
    2️⃣ 100% Of Fees to Charity Contributions
  </s.TextDescription>

  <s.TextDescription style={{ fontSize: 18, marginBottom: 20 }}>
    We're taking a step further by committing 100% of our fees towards contributions for charities aimed at tackling visual disorders. We believe that we can illuminate many lives, one color at a time.
  </s.TextDescription>

  <s.TextDescription style={{ fontSize: 22, fontWeight: 'bold', marginBottom: 10 }}>
    3️⃣ Building a Colorful Community
  </s.TextDescription>

  <s.TextDescription style={{ fontSize: 18, marginBottom: 20 }}>
    We believe in building a community that appreciates color, art, and the impact we can make together. We will host events, contests, and much more for our vibrant community.
  </s.TextDescription>
</s.Container>


          <s.SpacerLarge />
          <s.Container style={{ marginTop: 75 }} flex={1} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/colors2.gif"}
            />
            <s.TextTitle
              style={{ fontWeight: 'bold', textAlign: "center", marginTop: 25, color: 'white' }}
            >
              1 Hex NFT costs {selectedNetwork.DISPLAY_COST}{" "}
              {selectedNetwork.SYMBOL}.
            </s.TextTitle>
            <s.SpacerXSmall />
            <s.TextDescription
              style={{ fontWeight: 'bold', textAlign: "center", color: 'white' }}
            >

              {
                selectedNetwork.SYMBOL === 'ETH' ?
                  (<span>Excluding gas fees.</span>)
                  :
                  null
              }
            </s.TextDescription>
            <s.SpacerSmall />
            {blockchain.account === "" ||
              blockchain.smartContract === null ? (
              <s.Container ai={"center"} jc={"center"}>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    fontWeight: 'bold',
                    color: 'white',
                  }}
                >
                  Choose Network for Mint. Ethereum / Polygon (Polygon gas is less expensive!)
                </s.TextDescription>
                <s.SpacerSmall />
                <select style={{
                  padding: '15px 20px',
                  fontSize: '16px',
                  color: '#fff',
                  backgroundColor: 'var(--secondary)',
                  border: 'none',
                  borderRadius: '5px',
                  outline: 'none',
                  cursor: 'pointer',
                }}
                  onChange={handleChangeNetwork} value={networkId}>
                  <option value={1}>Ethereum</option>
                  {/* <option value='43114'>Avalanche</option> */}
                  {/* <option value='56'>Binance Smart Chain</option> */}
                  <option value={137}>Polygon</option>
                </select>

                <s.SpacerSmall />
                <StyledButton onClick={handleConnect}>
                  Connect
                </StyledButton>
                {blockchain.errorMsg !== "" ? (
                  <>
                    <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      {blockchain.errorMsg}
                    </s.TextDescription>
                  </>
                ) : null}
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={selectedNetwork.MARKETPLACE_LINK}>
                  View on {selectedNetwork.MARKETPLACE}
                </StyledLink>

              </s.Container>
            ) : (
              <>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    fontWeight: 'bold',
                    color: "white",
                  }}
                >
                  {feedback}
                </s.TextDescription>
                <s.SpacerMedium />
                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                  <StyledRoundButton
                    style={{ lineHeight: 0.4 }}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      decrementMintAmount();
                    }}
                  >
                    -
                  </StyledRoundButton>
                  <s.SpacerMedium />
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: 25,
                    }}
                  >
                    {mintAmount} ({(selectedNetwork.DISPLAY_COST * mintAmount).toFixed(4)} {selectedNetwork.SYMBOL})
                  </s.TextDescription>
                  <s.SpacerMedium />
                  <StyledRoundButton
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      incrementMintAmount();
                    }}
                  >
                    +
                  </StyledRoundButton>
                </s.Container>
                <s.SpacerSmall />
                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                  <StyledButton
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      claimNFTs();
                      getData();
                    }}
                  >
                    {claimingNft ? "BUSY" : "MINT"}
                  </StyledButton>
                </s.Container>
              </>
            )}
          </s.Container>
          <s.SpacerLarge />
        </ResponsiveWrapper>
      </s.Container>
    </s.Screen>
  );
}
export default App;